import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_config_provider, {
    theme: $setup.theme,
    "theme-vars-scope": "global",
    "theme-vars-dark": $setup.themeVarsDark,
    "theme-vars-light": $setup.themeVarsLight
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1
  }, 8, ["theme", "theme-vars-dark", "theme-vars-light"])]);
}