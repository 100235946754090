function setRem() {
  const scale = document.documentElement.clientWidth / 375;
  document.documentElement.style.fontSize = `${scale * 37.5}px`;
}

window.onresize = function () {
  setRem();
};

setRem();

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Locale } from "vant";
import { useCurrentLang } from "vant";
import utils from "./util/util";
import enUS from "vant/es/locale/lang/en-US";

const currentLang = useCurrentLang();

if (currentLang.value !== "zh-CN") {
  Locale.use("en-US", enUS);
}

import VConsole from 'vconsole';
if (process.env.NODE_ENV === "local" || process.env.NODE_ENV === "development") {
  const vConsole = new VConsole();
  console.log(vConsole);
}

import "./assets/font/iconfont.css";

import Vant, { showToast, showNotify, showConfirmDialog, showLoadingToast, closeToast } from "vant";
import "vant/lib/index.css";
import "./assets/css/index.stylus";
import "vant/es/toast/style";
import "vant/es/dialog/style";
import "vant/es/notify/style";

import api from "./axios/index";

const app = createApp(App);

// 全局注册
app.config.globalProperties.$toast = showToast;
app.config.globalProperties.$closeToast = closeToast;
app.config.globalProperties.$loadingToast = showLoadingToast;
app.config.globalProperties.$notify = showNotify;
app.config.globalProperties.$confirmDialog = showConfirmDialog;
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$axios = api;

app.use(Vant).use(store).use(router).mount("#app");
