import { createRouter, createWebHistory } from "vue-router";
import noLoginRoutes from "./modules/noLoginRoutes";
import loginedRoutes from "./modules/loginedRoutes";

const routes = [...noLoginRoutes, ...loginedRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("router beforeEach =>>>>>>>>>>>>", to, isLoggedIn());
  document.title = to.meta.title ? to.meta.title : "";
  if (to.name === "login") {
    if (isLoggedIn()) {
      next("/");
    } else {
      next();
    }
  } else {
    if (to.matched.some(record => record.meta.isNeedLogin)) {
      // 需要登录的路由
      if (!isLoggedIn()) {
        // 如果用户未登录，重定向到登录页面
        next("/login");
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

function isLoggedIn() {
  const vuexDATA = JSON.parse(localStorage.getItem("vuex"));
  console.log("vuexDATA", vuexDATA);
  const token = vuexDATA?.token;
  // const curr_team = vuexDATA?.curr_team;
  // console.log("isLoggedIn curr_team", curr_team, token);
  // && Object.keys(curr_team).length > 0
  if (token) {
    return true;
  } else {
    return false;
  }
}

export default router;
