import axios_ from "../axios";

export default {
  getGptConfigDatas() {
    return new Promise((resolve, reject) => {
      axios_("get", `api/pandagpt/v1/config/datas`)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  getGptModels() {
    return new Promise((resolve, reject) => {
      axios_("get", `api/pandagpt/v1/getGptModel`, { page: 1 })
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 获取图片验证码接口
   * @returns
   */
  getCaptchas() {
    return new Promise((resolve, reject) => {
      axios_("get", `api/pandagpt/v1/captchas`)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 发送短信验证码接口
   * @returns
   */
  sendSmsCode(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/sendSmsCode`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 手机号登录注册接口
   * @returns
   */
  phoneLogin(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/phoneLogin`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 账号密码登录
   * @returns
   */
  numberLogin(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/login`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 手机号/邮箱注册
   * @returns
   */
  phoneRegister(data, flag) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/${flag ? "mobileRegister" : "emailRegister"}`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 邮箱验证码
   * @returns
   */
  registerSendEmailCode(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/sendEmailCode2`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 修改密码
   * @returns
   */
  ResetPassword(data, flag) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/${flag ? "mobileResetPassword" : "emailResetPassword"}`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 工作台跳转登录注册接口
   * @returns
   */
  redirectLogin(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/singleSignOn`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 退出登录
   * @returns
   */
  logout() {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/logout`)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 个人信息
   * @returns
   */
  personalInformation() {
    return new Promise((resolve, reject) => {
      axios_("GET", `api/pandagpt/v1/userCenter/detail`)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 修改昵称
   * @returns
   */
  editNickName(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/userCenter/editNickName`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 图片验证码
   * @returns
   */
  ImageCode(param) {
    return new Promise((resolve, reject) => {
      axios_("GET", `api/pandagpt/v1/captchas`, param)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 验证短信验证码
   * @returns
   */
  authVeriCodeTwo(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `/api/pandagpt/v1/userCenter/authVeriCodeTwo`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  // 修改手机号
  editPhoneStore(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/userCenter/editPhoneStore`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  // 添加手机号
  addPhoneStore(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/userCenter/addPhoneStore`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  // 验证旧邮箱验证码
  authEmailCode(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/userCenter/authEmailCode`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  // 添加/修改邮箱
  addOrEditEmailStore(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/userCenter/${data?.auth_verification_key ? "editEmailStore" : "addEmailStore"}`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  // 上传头像
  uploadImg(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/userCenter/uploadImg`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  // 设置账号密码
  setAccountPassword(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/userCenter/setAccountPassword`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  // 更换密码
  editPassword(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/userCenter/setPassword`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  // 修改账号
  editAccount(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/userCenter/setAccount`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  // 跳过账号密码设置
  skipPerfectStatus() {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/userCenter/skipPerfectStatus`)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
  * 登录页面获取邀请信息
  */
  billingRules() {
    return new Promise((resolve, reject) => {
      axios_("GET", `api/pandagpt/v1/config/billingRules`)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 登录页面获取邀请信息
   * @returns
   */
  getInvitationInformation(data) {
    return new Promise((resolve, reject) => {
      axios_("GET", `api/pandagpt/v1/team/invitationInformation`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 当前登录用户的团队列表接口
   * @returns
   */
  getUserTeamList() {
    return new Promise((resolve, reject) => {
      axios_("get", `api/pandagpt/v1/team/userTeamLists`)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 切换团队接口
   * @returns
   */
  switchTeamHandle(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/team/switchTeam`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 创建团队接口
   * @returns
   */
  createTeamHandle(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/team/insert`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 微信网页授权登录
   * @returns
   */
  wechatCallback(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/wechatCallback`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
};
