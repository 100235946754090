const loginRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/loginOrAuth/login.vue'),
    meta: {
      title: 'JoinMC H5登录',
      isNeedLogin: false,
    }
  },
  {
    path: '/regist',
    name: 'regist',
    component: () => import('@/views/loginOrAuth/regist.vue'),
    meta: {
      title: 'JoinMC H5注册',
      isNeedLogin: false,
    }
  },
  {
    path: '/viewOnWebsite',
    name: 'viewOnWebsite',
    component: () => import('@/views/beforeLoginPages/viewOnWebsite.vue'),
    meta: {
      title: 'JoinMC',
      isNeedLogin: false,
    }
  },
]
export default loginRoutes;