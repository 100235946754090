import { createApp, h } from "vue";
import { Dialog, Form, Field, RadioGroup, Radio, showFailToast } from "vant";
import "vant/lib/index.css"; // 确保引入了Vant的CSS
import "../assets/css/createTeamPrompt.stylus"

// 定义createTeamPrompt函数
export function createTeamPrompt(options) {
  const { models, onConfirm, onCancel } = options;

  const app = createApp({
    data() {
      return {
        dialogVisible: true, // 初始化时即显示对话框
        team_name: "",
        gpt_model_id: models.length > 0 ? models[0].id : null, // 默认选中第一个模型
      };
    },
    mounted() {
      this.dialogVisible = true;
    },
    methods: {
      submitForm() {
        if (this.team_name && this.gpt_model_id) {
          if (this.team_name.length > 1 && this.team_name.length < 11) {
            onConfirm({ team_name: this.team_name, model: this.gpt_model_id });
            this.dialogVisible = false; // 提交后关闭对话框
          } else {
            showFailToast("团队名称字符2-10个");
          }
        } else {
          showFailToast("请完整填写表单");
        }
      },
      cancelForm() {
        onCancel();
        this.dialogVisible = false; // 取消时关闭对话框
      },
    },
    render() {
      return h(
        Dialog,
        {
          show: this.dialogVisible,
          "onUpdate:modelValue": value => {
            this.dialogVisible = value;
          },
          showCancelButton: true,
          title: "创建团队",
          onConfirm: this.submitForm,
          onCancel: this.cancelForm,
        },
        {
          default: () => [
            h(
              Form,
              {
                labelWidth: "5.5em",
                class: "custom-form", // 应用自定义样式
              },
              {
                default: () => [
                  h(Field, {
                    modelValue: this.team_name,
                    "onUpdate:modelValue": value => {
                      this.team_name = value;
                    },
                    label: "团队名称",
                    placeholder: "请输入团队名称",
                    class: "custom-field-input", // 应用自定义样式
                  }),
                  h(
                    Field,
                    {
                      label: "默认AI模型",
                      clickable: true,
                      readonly: true,
                    },
                    {
                      input: () =>
                        h(
                          RadioGroup,
                          {
                            direction: "horizontal",
                            modelValue: this.gpt_model_id,
                            "onUpdate:modelValue": value => {
                              this.gpt_model_id = value;
                            },
                          },
                          {
                            default: () =>
                              models.map(model =>
                                h(
                                  Radio,
                                  {
                                    name: model.id,
                                    class: "custom-radio-button",
                                  },
                                  model.gpt_model_nickname
                                )
                              ),
                          }
                        ),
                    }
                  ),
                ],
              }
            ),
          ],
        }
      );
    },
  });

  const div = document.createElement("div");
  document.body.appendChild(div);
  app.mount(div);
}
