import axios_ from "../axios";
import { EventSourcePolyfill } from "event-source-polyfill";
export default {
  /**
   * 对话页面获取会话列表
   * @param {*} page
   * @param {*} page_num
   * @param {*} sess_name 会话标题
   */
  getChats(options) {
    let { page, page_num, sess_name } = options;
    return new Promise((resolve, reject) => {
      axios_("get", `api/pandagpt/v1/sess/getChatSess`, {
        page: page,
        page_num: page_num,
        sess_name: sess_name,
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  getGptKey(param) {
    return new Promise((resolve, reject) => {
      axios_("post", `api/pandagpt/v1/getKey`, param)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 对话页面问答接口
   * @param {*} text
   * @param {*} s_id 会话ID
   * @param {*} role_id 角色id
   * @param {*} gpt_model_id gpt模型id
   * @returns
   */
  chatStreamByLib(key, s_id, role_id, gpt_model_id) {
    let source = new EventSourcePolyfill(`${process.env.VUE_APP_AI_API_URL}api/pandagpt/v1/sess/informationChatStream?key=${key}&s_id=${s_id}&role_id=${role_id}&gpt_model_id=${gpt_model_id}`, {
      headers: {
        token: localStorage.getItem("token"),
      },
      heartbeatTimeout: 1000 * 60 * 5, // 心跳超时时间，单位为毫秒
    });
    return source;
  },
  /**
   * 对话 生成会话标题
   */
  async createChatName(option) {
    return new Promise((resolve, reject) => {
      axios_("post", `api/pandagpt/v1/sess/mobileCreateSessName`, option)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 删除会话
   */
  delChatSess(data) {
    return new Promise((resolve, reject) => {
      axios_("post", `api/pandagpt/v1/sess/delChatSess`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 获取某个会话的历史问答
   */
  getChatHistory(options) {
    return new Promise((resolve, reject) => {
      axios_("get", `api/pandagpt/v1/sess/getChatSessHis`, options)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * 语音转文字
   */
  speechToText(data) {
    return new Promise((resolve, reject) => {
      axios_("POST", `api/pandagpt/v1/tool/speechToText`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },

};
