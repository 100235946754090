export default {
  isWX() {
    return /micromessenger/i.test(navigator.userAgent);
  },
  getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null)
      return decodeURIComponent(r[2]);
    return null;
  },
};
